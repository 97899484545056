<template>
    <div class="moveable" ref="item" :style="getStyle" @pointerdown="dragStartEvent">
        <div class="moveable__content"><slot></slot></div>
    </div>
</template>

<script>
export default {
    data() {return {
        size: {
            width: null,
            height: null,
        },
        position: {
            x: null,
            y: null
        }
    }},

    mounted() {
        let rects = this.$refs.item.getBoundingClientRect();
        this.position.x = rects.x;
        this.position.y = rects.y;
        this.size.width = rects.width;
        this.size.height = rects.height;

        window.addEventListener('resize', this.windowResizeEvent);
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.windowResizeEvent);
    },

    methods: {
        windowResizeEvent() {
            // For dynamic font size, like clamp()
            let rects = this.$refs.item.getBoundingClientRect();
            this.size.width = rects.width;
            this.size.height = rects.height;

            // For visibility
            if(this.position.x > window.innerWidth - this.size.width) {
                this.position.x = window.innerWidth - this.size.width;
            }

            if(this.position.y > window.innerHeight - this.size.height) {
                this.position.y = window.innerHeight - this.size.height;
            }
        },

        dragStartEvent() {
            window.addEventListener('pointermove', this.dragEvent);
            window.addEventListener('pointerup', this.dragStopEvent);
        },

        dragEvent(event) {
            let newPositionX = this.position.x + event.movementX;
            let newPositionY = this.position.y + event.movementY;

            if(newPositionX < 0) {
                newPositionX = 0;
            }

            if(newPositionY < 0) {
                newPositionY = 0;
            }

            if(newPositionX > window.innerWidth - this.size.width) {
                newPositionX = window.innerWidth - this.size.width;
            }

            if(newPositionY > window.innerHeight - this.size.height) {
                newPositionY = window.innerHeight - this.size.height;
            }

            this.position.x = newPositionX;
            this.position.y = newPositionY;
        },

        dragStopEvent() {
            window.removeEventListener('pointermove', this.dragEvent);
            window.removeEventListener('pointerup', this.dragStopEvent);
        },
    },

    computed: {
        getStyle() {
            return {
                left: '' + this.position.x + 'px',
                top: '' + this.position.y + 'px',
            };
        },
    }
}
</script>
